<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
          404
        </h1>
        <h2 class="subtitle">
          {{ $t("We couldn't find this page...") }}</h2>
      </div>
    </div>
    <div class="hero-foot has-text-centered">
      <b-button tag="router-link" to="/" type="is-primary" size="is-large">{{$t('Go home')}}</b-button>
    </div>
  </section>
</template>

<script>
export default {
  name: "404",
  title(){ return this.$t('Page not found, sorry for that...');},

};
</script>